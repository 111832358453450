<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content main_content">
      <div class="item_box">
        <div class="top">
          <div class="title">急性胃炎</div>
          <div class="type">在线问诊</div>
        </div>
        <div class="p" style="margin-top: 8px;">门诊时间：2020-06-24</div>
        <div class="p"><label>杨俊涛</label> 主任医师</div>
        <div class="p">科室：呼吸科</div>
        <div class="p">医院：罗湖人民中心医院</div>
      </div>

      <div class="item_box">
        <div class="top">
          <div class="title">症状</div>
          <div class="time">2020-04-24</div>
        </div>
        <div class="p" style="margin-top: 10px;">下腹疼痛 [呈剧烈疼痛] 、恶心3天</div>
      </div>

      <div class="item_box">
        <div class="top">
          <div class="title">用药清单</div>
          <div class="time">2020-04-24</div>
        </div>
        <div class="line_box" style="margin-top: 17px;">
          <div>
            <p class="name">头孢拉丁缓释片0.5mg*20</p>
            <p class="info">用法每日二次 一次0.5mg/一片</p>
          </div>
          <div class="num">6盒</div>
        </div>
        <div class="line_box">
          <div>
            <p class="name">头孢拉丁缓释片0.5mg*20</p>
            <p class="info">用法每日二次 一次0.5mg/一片</p>
          </div>
          <div class="num">2盒</div>
        </div>
        <div class="btn_box">
          <div class="btn">详情</div>
        </div>
      </div>

      <div class="item_box">
        <div class="top">
          <div class="title">检验记录</div>
          <div class="time">2020-04-24</div>
        </div>
        <div class="line_box" style="margin-top: 17px;">
          <div class="name">血清胃蛋白酶原</div>
          <div class="result c1">3项异常</div>
        </div>
        <div class="line_box">
          <div class="name">癌症胚胎抗原（CEA）</div>
          <div class="result c2">正常</div>
        </div>
        <div class="btn_box">
          <div class="btn">详情</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: false,
      id: this.$route.query.id,
    }
  },
  computed: {
  },
  components: {
  },
  created () {
  },
  methods: {

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_content {
  background: #F7F7F7;
  padding: 0px 16px 20px;
}
.item_box {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
  opacity: 1;
  border-radius: 10px;
  margin-top: 15px;
  padding: 17px 10px;
  & .top {
    display: flex;
    align-items: center;
    & .title {
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #292929;
    }
    & .type {
      line-height: 22px;
      color: #0088FF;
    }
    & .time {
      line-height: 22px;
      color: #999;
    }
  }
  & .p {
    line-height: 20px;
    color: #292929;
    margin-top: 5px;
    & label {
      color: #0088FF;
    }
  }
}
.line_box {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  & .name {
    color: #292929;
    line-height: 20px;
  }
  & .info {
    color: #919191;
    line-height: 20px;
    font-size: 12px;
  }
  & .num {
    font-weight: 500;
    line-height: 24px;
    color: #292929;
  }
  & .result {
    line-height: 20px;
    color: #FF0000;
    &.c2 {
      color: #00AF28;
    }
  }
}
.btn_box {
  margin-top: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  & .btn {
    width: 71px;
    height: 28px;
    background: #0088FF;
    opacity: 1;
    border-radius: 22px;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
